export default {
  created() {
    this.updateTitle();
  },
  methods: {
    updateTitle() {
      const title = this.tabTitle();

      if (title.indexOf('{') === -1) {
        document.title = title;
      }
    },
    tabTitle() {
      const { title } = this.$route.meta;
      let newTitle = title;

      if (title.indexOf('{') >= 0) {
        const replaces = this.metaTitleReplacement();

        Object.keys(replaces).forEach(key => {
          if (replaces[key]) {
            newTitle = newTitle.replace(`{${key}}`, replaces[key]);
          }
        });
      }

      return newTitle;
    },
    metaTitleReplacement() {
      return {};
    },
  },
};
