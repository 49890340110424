<template>
  <div>
    <sun-form v-if="currentProduct" @submit="onSubmit">
      <card-form>
        <template #title> Info </template>
        <template #form>
          <form-row>
            <template #left>
              <sun-label-group text="Name">
                <sun-input :value="currentProduct.name" readonly disabled type="text" />
              </sun-label-group>
            </template>
            <template #right>
              <sun-label-group text="KPI" :text-info="textInfos.kpi">
                <asterix-input v-model="currentProduct.kpi" type="text" disabled />
              </sun-label-group>
            </template>
          </form-row>
          <!-- <form-row>
            <template #left>
              <sun-label-group text="Tags" :text-info="textInfos.tag">
                <sun-select v-model="currentProduct.tag" placeholder="IAS, MOAT..." :options="getTags" />
              </sun-label-group>
            </template>
          </form-row> -->
        </template>
      </card-form>

      <card-form>
        <template #title> Salesforce's Management </template>
        <template #form>
          <form-row>
            <template #left>
              <sun-label-group text="Platform Reporting ID" :text-info="textInfos.prid">
                <sun-input v-model="currentProduct.reportId" type="text" disabled />
              </sun-label-group>
            </template>
            <template #right>
              <sun-label-group text="SF Line Item ID" :text-info="textInfos.lineItemId">
                <sun-input v-model="currentProduct.lineItemId" type="text" disabled />
              </sun-label-group>
            </template>
          </form-row>

          <form-row>
            <template #left>
              <sun-label-group text="Advertiser" :text-info="textInfos.advertiser">
                <sun-input v-model="currentProduct.advertiser.name" type="text" disabled />
              </sun-label-group>
            </template>
            <template #right>
              <sun-label-group text="Account" :text-info="textInfos.accountName">
                <sun-input v-model="currentProduct.accountName" type="text" disabled />
              </sun-label-group>
            </template>
          </form-row>

          <form-row>
            <template #left>
              <sun-label-group text="Account Manager" :text-info="textInfos.accountManager">
                <sun-input
                  v-model="currentProduct.accountManager"
                  type="text"
                  placeholder="Our Account Manager Guy"
                  disabled
                />
              </sun-label-group>
            </template>
            <template #right>
              <sun-label-group text="Opportunity Owner" :text-info="textInfos.opportunityOwner">
                <sun-input v-model="currentProduct.opportunity.ownerName" type="text" disabled />
              </sun-label-group>
            </template>
          </form-row>

          <form-row>
            <template #left>
              <sun-label-group text="Start Date" :text-info="textInfos.startDate">
                <sun-input
                  :value="currentProduct.initialDate | stringToDate | applyDateFormatter"
                  type="text"
                  disabled
                />
              </sun-label-group>
            </template>
            <template #right>
              <sun-label-group text="End Date" :text-info="textInfos.endDate">
                <sun-input :value="currentProduct.endDate | stringToDate | applyDateFormatter" type="text" disabled />
              </sun-label-group>
            </template>
          </form-row>

          <form-row>
            <template #left>
              <sun-label-group text="Office" :text-info="textInfos.office">
                <sun-input v-model="currentProduct.opportunity.office" type="text" disabled />
              </sun-label-group>
            </template>
            <template #right>
              <sun-label-group text="Billing Entity" :text-info="textInfos.billingEntity">
                <sun-input v-model="currentProduct.opportunity.billingEntityName" type="text" disabled />
              </sun-label-group>
            </template>
          </form-row>

          <form-row>
            <template #left>
              <sun-label-group text="RATE" :text-info="textInfos.rate">
                <sun-select v-model="currentProduct.rate" placeholder="CPL, CPM..." :options="getRates" disabled />
              </sun-label-group>
            </template>
            <template #right>
              <sun-label-group text="Booked volume" :text-info="textInfos.bookedVolume">
                <asterix-input
                  v-model="currentProduct.volume"
                  type="number"
                  :min="0"
                  placeholder="138235"
                  disabled
                  auto-validate
                />
              </sun-label-group>
            </template>
          </form-row>

          <form-row>
            <template #left>
              <sun-label-group text="Format" :text-info="textInfos.format">
                <sun-input v-model="currentProduct.format" type="text" placeholder="skin, native, video..." disabled />
              </sun-label-group>
            </template>
            <template #right>
              <sun-label-group text="Net Price" :text-info="textInfos.netPrice">
                <currency-selector
                  v-model="currentProduct.netPrice"
                  class="w-full"
                  placeholder="0.00"
                  currency-disabled
                  disabled
                />
              </sun-label-group>
            </template>
          </form-row>

          <form-row>
            <template #left>
              <sun-label-group text="AdOps" :text-info="textInfos.adops">
                <sun-input v-model="currentProduct.adopsName" type="text" placeholder="Our AdOps Guy" disabled />
              </sun-label-group>
            </template>
            <template #right>
              <sun-label-group text="Price" :text-info="textInfos.price">
                <currency-selector
                  v-model="currentProduct.price"
                  class="w-full"
                  placeholder="0.00"
                  currency-disabled
                  disabled
                />
              </sun-label-group>
            </template>
          </form-row>

          <form-row>
            <template #left>
              <sun-label-group text="Opportunity Record Type" :text-info="textInfos.opportunityRecordType">
                <sun-input v-model="currentProduct.opportunity.recordTypeName" type="text" disabled />
              </sun-label-group>
            </template>
          </form-row>
        </template>
      </card-form>

      <card-form class="mb-12">
        <template #title> Management </template>
        <template #form>
          <form-row>
            <template #left>
              <sun-label-group text="Client conversions" :text-info="textInfos.conversions">
                <asterix-input
                  v-model="currentProduct.clientConversion"
                  type="number"
                  :min="0"
                  placeholder="1666"
                  auto-validate
                />
              </sun-label-group>
            </template>
            <template #right>
              <sun-label-group text="Inversion Net" :text-info="textInfos.investment">
                <currency-selector
                  v-model="currentProduct.inversionNet"
                  class="w-full"
                  placeholder="0.00"
                  currency-disabled
                />
              </sun-label-group>
            </template>
          </form-row>
        </template>
      </card-form>

      <card-form class="mb-12">
        <template #title> IAS Metrics </template>
        <template #form>
          <form-row>
            <sun-file-uploader :accept="acceptIASFile" @change="clickFile" @error="wrongFile" />
          </form-row>
          <div class="text-right mt-4">
            <sun-button
              class="bg-gray-700 text-white rounded shadow-md hover:bg-gray-900 custom-p-1 text-xs"
              color="gray"
              variant="pill"
              :disabled="iasFileError"
              @click="updateProductViewIAS"
            >
              Upload
            </sun-button>
          </div>
        </template>
      </card-form>

      <div class="text-left mb-4 mt-12">
        <save-button class="ml-0" />
      </div>
    </sun-form>
    <loading v-else />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import CardForm from '@/components/atoms/CardForm';
import SaveButton from '@/components/atoms/SaveButton';
import { RATES, TAGS } from '@/model/modules/thirdParty/product/ProductMetricsConstants';
import CurrencySelector from '@/components/atoms/CurrencySelector/CurrencySelector';
import Product from '@/entities/ThirdParty/Product';
import { updateProductMetrics, updateProductViewabilityIAS } from '@/services/modules/ThirdParty/product';
import Loading from '@/components/atoms/LogoLoading';
import metaInfo from '@/mixins/common/metaInfo';
import { Toast } from '@/model/shared/Toast';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import FormRow from '@/components/atoms/FormRow/FormRow';
import { deepClone } from '@/utils/deepClone';
import { applyDateFormatters, stringToDate } from '@/filters/dateFilters';
import { spanishDateFormatter } from '@/model/shared/DateFormatter';
import AsterixInput from '@/components/atoms/AsterixInput';

export default {
  name: 'ProductMetrics',
  components: {
    FormRow,
    CardForm,
    SaveButton,
    CurrencySelector,
    Loading,
    AsterixInput,
  },
  filters: {
    stringToDate,
    applyDateFormatter: date => applyDateFormatters(date, spanishDateFormatter),
  },
  mixins: [metaInfo],
  props: {
    entity: {
      type: Product,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      acceptIASFile: [
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      ].join(','),
      textInfos: {
        kpi: 'Performance indicator',
        tags: 'Allows trafficker to set the tag included on the creative',
        prid: 'Salesforce product list identifier',
        rate: 'Product purchase model',
        price: 'Product purchase price',
        format: 'Creative format',
        investment: 'Investment value in Salesforce',
        conversions: 'Buyer side conversions',
        lineItemId: 'Salesforce Product ID',
        advertiser: 'Salesforce Opportunity Advertiser Name',
        accountName: 'Salesforce Opportunity Account Name',
        opportunityOwner: 'Salesforce Opportunity Owner',
        startDate: 'Salesforce Product Start Date',
        endDate: 'Salesforce Product End Date',
        office: 'Salesforce Opportunity Office',
        billingEntity: 'Salesforce Opportunity Billing Entity',
        opportunityRecordType: 'Salesforce Opportunity Record Type',
        accountManager: 'Salesforce Opportunity Account Manager',
        adOps: 'Salesforce Product AdOps',
        netPrice: 'Salesforce Product Net Price',
        bookedVolume: 'Salesforce Product Booked Volume',
      },
      getTags: TAGS,
      getRates: RATES,
      currentProduct: null,
      currentProductTag: null,
      iasFile: null,
      iasFileError: false,
    };
  },
  watch: {
    entity(newEntity) {
      this.reloadCurrentProduct(newEntity);
    },
  },
  created() {
    this.reloadCurrentProduct();
  },
  methods: {
    ...mapActions({ createToast: CREATE_TOAST }),
    reloadCurrentProduct(newEntity = this.entity) {
      this.currentProduct = deepClone(newEntity);
      this.updateTitle();
    },
    metaTitleReplacement() {
      return { product: this.currentProduct?.name } || {};
    },
    async onSubmit(submitEvent) {
      if (submitEvent.valid) {
        try {
          await this.updateProduct();
          this.createToast(Toast.success('Data updated', 'Data was updated successfully'));
        } catch (e) {
          this.createToast(Toast.error('Comment could not be updated', e.message));
        }
      } else {
        this.createToast(Toast.error('Values not updated', 'Please fill all the required fields'));
      }

      this.$emit('reload');
    },
    async updateProduct() {
      await updateProductMetrics(this.currentProduct.metricsPayload());
    },
    async updateProductViewIAS() {
      if (!this.iasFile) return;
      if (this.iasFileError) {
        this.createToast(Toast.error('Values not updated', 'Please upload a valid file'));
        return;
      }
      try {
        await updateProductViewabilityIAS(this.currentProduct.id, this.iasFile);
        this.createToast(Toast.success('Data updated', 'Data was updated successfully'));
      } catch (e) {
        this.createToast(Toast.error('Comment could not be updated', e.message));
      }
    },
    clickFile(files) {
      this.iasFile = files?.[0];
      this.iasFileError = false;
    },
    wrongFile() {
      this.iasFileError = true;
    },
  },
};
</script>
