<template>
  <div class="flex w-full justify-center relative py-64 z-20">
    <div class="spinner double absolute w-32 h-32" />
    <custom-brand-img v-if="customDomain" :type="customImgTypes.BG_LIGHT" img-class="w-32 h-32" is-icon />
    <sun-media-icon-dark v-else class="w-32 h-32" />
  </div>
</template>

<script>
import SunMediaIconDark from '../icons/SunMediaIconDark';
import { isCustomDomain, customImgTypes } from '@/utils/domain';
import CustomBrandImg from '@/components/atoms/CustomBrandImg.vue';

export default {
  name: 'Loading',
  components: {
    SunMediaIconDark,
    CustomBrandImg,
  },
  data() {
    return {
      customDomain: isCustomDomain(),
      customImgTypes,
    };
  },
};
</script>

<style scoped>
.spinner {
  border-radius: 50%;
  border: 10px solid white;
  border-top-color: #edf2f7;
  -webkit-animation: 1.5s spin infinite linear;
  animation: 1.5s spin infinite linear;
}

.spinner.double {
  border-bottom-color: #edf2f7;
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
