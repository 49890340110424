<template>
  <sun-button
    :id="id"
    class="custom-p-1 px-6"
    :color="color"
    :loading="loading"
    :disabled="loading || disabled"
    :type="type"
    variant="pill"
    @click="$emit('click')"
  >
    <slot>
      <div class="flex items-center">
        <slot name="icon">
          <save-svg class="ml-1 w-4 h-4 mr-2 text-gray-200" />
        </slot>
        <slot name="text">
          <span class="text-sm text-gray-100 h-4">{{ text }}</span>
        </slot>
      </div>
    </slot>
  </sun-button>
</template>
<script>
import SaveSvg from '../icons/SaveSvg';

export default {
  name: 'SaveButton',
  components: {
    SaveSvg,
  },
  props: {
    id: {
      type: String,
      default: () => 'submit-button',
    },
    color: {
      type: String,
      default: () => 'orange',
    },
    text: {
      type: String,
      default: () => 'Save',
    },
    type: {
      type: String,
      default: () => 'submit',
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
  },
};
</script>
