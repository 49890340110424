var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("svg", { attrs: { viewBox: "0 0 448 512" } }, [
    _c("path", {
      staticClass: "fill-current",
      attrs: {
        d: "M433.941 129.941l-83.882-83.882A48 48 0 0 0 316.118 32H48C21.49 \n    32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 \n    48-48V163.882a48 48 0 0 0-14.059-33.941zM272 80v80H144V80h128zm122 \n    352H54a6 6 0 0 1-6-6V86a6 6 0 0 1 6-6h42v104c0 13.255 10.745 24 24 \n    24h176c13.255 0 24-10.745 24-24V83.882l78.243 78.243a6 6 0 0 1 1.757 \n    4.243V426a6 6 0 0 1-6 6zM224 232c-48.523 0-88 39.477-88 88s39.477 \n    88 88 88s88-39.477 88-88s-39.477-88-88-88zm0 128c-22.056 \n    0-40-17.944-40-40s17.944-40 40-40s40 17.944 40 40s-17.944 40-40 40z",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }