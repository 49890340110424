var render = function render(_c, _vm) {
  return _c(
    "div",
    {
      class: [
        "form-row flex flex-col lg:flex-row w-full",
        _vm.data.class,
        _vm.data.staticClass,
      ],
      style: [_vm.data.style, _vm.data.staticStyle],
    },
    [
      _vm._t("default", function () {
        return [
          _c(
            "div",
            { staticClass: "w-full lg:w-1/2 lg:mr-4" },
            [_vm._t("left")],
            2
          ),
          _c(
            "div",
            { staticClass: "w-full mt-6 lg:w-1/2 lg:ml-4 lg:mt-0" },
            [_vm._t("right")],
            2
          ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }