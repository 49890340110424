var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "sun-button",
    {
      staticClass: "custom-p-1 px-6",
      attrs: {
        id: _vm.id,
        color: _vm.color,
        loading: _vm.loading,
        disabled: _vm.loading || _vm.disabled,
        type: _vm.type,
        variant: "pill",
      },
      on: {
        click: function ($event) {
          return _vm.$emit("click")
        },
      },
    },
    [
      _vm._t("default", function () {
        return [
          _c(
            "div",
            { staticClass: "flex items-center" },
            [
              _vm._t("icon", function () {
                return [
                  _c("save-svg", {
                    staticClass: "ml-1 w-4 h-4 mr-2 text-gray-200",
                  }),
                ]
              }),
              _vm._t("text", function () {
                return [
                  _c("span", { staticClass: "text-sm text-gray-100 h-4" }, [
                    _vm._v(_vm._s(_vm.text)),
                  ]),
                ]
              }),
            ],
            2
          ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }