import { render, staticRenderFns } from "./ProductMetrics.vue?vue&type=template&id=c00f5764&"
import script from "./ProductMetrics.vue?vue&type=script&lang=js&"
export * from "./ProductMetrics.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/gitlab-runner/builds/D6nERZR5/0/sunmedia/asterix/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('c00f5764')) {
      api.createRecord('c00f5764', component.options)
    } else {
      api.reload('c00f5764', component.options)
    }
    module.hot.accept("./ProductMetrics.vue?vue&type=template&id=c00f5764&", function () {
      api.rerender('c00f5764', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/organisms/modules/thirdParty/product/ProductMetrics.vue"
export default component.exports