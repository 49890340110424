var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex w-full justify-center relative py-64 z-20" },
    [
      _c("div", { staticClass: "spinner double absolute w-32 h-32" }),
      _vm.customDomain
        ? _c("custom-brand-img", {
            attrs: {
              type: _vm.customImgTypes.BG_LIGHT,
              "img-class": "w-32 h-32",
              "is-icon": "",
            },
          })
        : _c("sun-media-icon-dark", { staticClass: "w-32 h-32" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }